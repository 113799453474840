import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const AboutTwo = () => {
  return (
    <div className="rwt-about-area about-style-2 rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-7 mt_md--30 mt_sm--30">
            <div className="content">
              <div className="section-title">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h4 className="subtitle">
                    <span className="theme-gradient">
                      Realistic Voice Cloning in Seconds
                    </span>
                  </h4>
                  <h2 className="title mt--10">
                    Bring Your Characters to Life with Custom Voices
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p>
                    With PodcastPilot, you can easily clone voices for your AI
                    characters. Simply import an audio file or record a
                    30-second clip of your own voice, and our advanced
                    technology will create a realistic voice clone in seconds.
                    Give your characters unique and engaging voices that bring
                    your podcasts to life and captivate your audience.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                src="./images/about/assign-voices-2.png"
                alt="About Images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
