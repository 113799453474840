import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const AboutTwo = () => {
  return (
    <div className="rwt-about-area about-style-2 rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-7 mt_md--30 mt_sm--30">
            <div className="content">
              <div className="section-title">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h4 className="subtitle">
                    <span className="theme-gradient">#PodcastCreators</span>
                  </h4>
                  <h2 className="title mt--10">
                    Unlock Rewards with PodcastPilot's Creator Program
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p>
                    With the Creator Program, you can share your podcasts on
                    TikTok and unlock exclusive rewards like PodcastPilot Plus,
                    unlimited podcasting, more creation credits, early access
                    features, and more. Simply export a 20-60 second highlight
                    from your podcast in compatible formats and upload it to
                    social media. Join the Creator Program today and unleash
                    your podcasting potential!
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                src="./images/about/creator-program.png"
                alt="About Images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
