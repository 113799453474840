import React from "react";
import SEO from "../../common/SEO";

import HeaderOne from "../../common/header/HeaderOne";
import FooterTwo from "../../common/footer/FooterTwo";
import Copyright from "../../common/footer/Copyright";

const Company = () => {
  return (
    <>
      <SEO title="Terms" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapBottom">
          <div className="container">
            <div className="col align-items-center">
              <div className="col-lg-12">
                <div className="content">
                  <h3 className="title">Terms & Conditions</h3>
                </div>
              </div>
              <div className="col-lg-12">
                <p className="mb--10">
                  Effective Date: These Terms and Conditions are effective as of
                  [20th march 2024 (Version: v1.1.10)] and will remain in effect
                  except with respect to any changes in its provisions in the
                  future, which will be in effect immediately after being posted
                  on this page.
                </p>
                <p className="mb--10">Welcome to PodcastPilot!</p>
                <p className="mb--10">
                  These Terms and Conditions outline the rules and regulations
                  for the use of PodcastPilot, located at podcastpilot.ai, and
                  are an agreement between PlotPilot Ltd. ("us", "we", or "our")
                  and you ("User", "you" or "your"). By accessing and using
                  PodcastPilot, you accept and agree to be bound by these Terms
                  and Conditions in full. If you disagree with any part of these
                  terms, you must not use our app.
                </p>
                <p className="mb--10">
                  We are committed to ensuring that our Terms and Conditions are
                  accessible and understandable to all users. If you have any
                  questions or need clarification about any of the terms
                  outlined here, please contact us at accounts@plotpilot.ai for
                  further assistance.
                </p>
                <p className="mb--10">1. User Account and Use Eligibility</p>
                <p className="mb--10">
                  To use PodcastPilot, creating an account is mandatory. By
                  accepting these Terms, you confirm that you meet the minimum
                  age requirement of 12 years. Your account is your
                  responsibility, including the confidentiality of account
                  details and all activities conducted through your account.
                </p>
                <p className="mb--10">2. App Services</p>
                <p className="mb--10">
                  PodcastPilot offers AI-generated podcast services, where users
                  can engage in dialogues with AI-generated characters as either
                  the host or the guest. The content generated through our
                  services is for personal, non-commercial use only, unless
                  explicitly authorized by PlotPilot Ltd.
                </p>
                <p className="mb--10">
                  All intellectual property rights in and to the PodcastPilot
                  mobile application, including but not limited to its software,
                  content displayed within the app (such as text, graphics,
                  images, music, audio, and videos), interface, logos, and
                  trademarks, are owned by PlotPilot Ltd., its affiliates, or
                  its licensors. These elements are protected by international
                  copyright, trademark laws, patent laws, trade secrets, and
                  other proprietary rights and laws.
                </p>
                <p className="mb--10">
                  PlotPilot Ltd. grants you a revocable, non-exclusive,
                  non-transferable, limited license to download, install, and
                  use the app for your personal, non-commercial use strictly in
                  accordance with these Terms and Conditions.
                </p>
                <p className="mb--10">User Restrictions</p>
                <p className="mb--10">You agree not to:</p>
                <p className="mb--10">
                  • Modify, disassemble, reverse engineer, or decompile the
                  software, except as may be permitted by applicable law.
                </p>
                <p className="mb--10">
                  • Rent, lease, lend, sell, redistribute, or sublicense the
                  PodcastPilot app.
                </p>
                <p className="mb--10">
                  • Copy (except as expressly permitted by this license and the
                  Usage Rules), distribute, or create derivative works based on
                  the PodcastPilot app.
                </p>
                <p className="mb--10">
                  • Use the app for any purpose that is illegal or prohibited by
                  these Terms.
                </p>
                <p className="mb--10">
                  • Use the app in any manner that could interfere with,
                  disrupt, negatively affect, or inhibit other users from fully
                  enjoying the app, or that could damage, disable, overburden,
                  or impair the functioning of the app in any manner.
                </p>
                <p className="mb--10">Limitation of Liability</p>
                <p className="mb--10">
                  To the maximum extent permitted by applicable law, PlotPilot
                  Ltd. shall not be liable for any indirect, incidental,
                  special, consequential, or punitive damages, or any loss of
                  profits or revenues, whether incurred directly or indirectly,
                  or any loss of data, use, goodwill, or other intangible
                  losses, resulting from (a) your access to or use of or
                  inability to access or use the PodcastPilot service; (b) any
                  conduct or content of any third party on the PodcastPilot
                  service, including without limitation, any defamatory,
                  offensive, or illegal conduct of other users or third parties;
                  or (c) unauthorized access, use, or alteration of your
                  transmissions or content.
                </p>
                <p className="mb--10">Trademarks</p>
                <p className="mb--10">
                  The trademarks, service marks, and logos of PodcastPilot and
                  PlotPilot Ltd. used and displayed in the app are registered
                  and unregistered trademarks or service marks of PlotPilot Ltd.
                  Other company, product, and service names located in the app
                  may be trademarks or service marks owned by third parties (the
                  "Third-Party Trademarks", and, collectively with PlotPilot
                  Ltd. Trademarks, the "Trademarks"). Nothing in this app or in
                  these Terms and Conditions should be construed as granting, by
                  implication, estoppel, or otherwise, any license or right to
                  use any Trademark displayed in the app without the prior
                  written consent of PlotPilot Ltd. specific for each such use.
                  The Trademarks may not be used to disparage PlotPilot Ltd. or
                  the applicable third-party, PlotPilot Ltd.’s or third-party’s
                  products or services, or in any manner (using commercially
                  reasonable judgment) that may damage any goodwill in the
                  Trademarks. All goodwill generated from the use of any
                  PlotPilot Ltd. Trademark will inure to PlotPilot Ltd.’s
                  benefit.
                </p>
                <p className="mb--10">4. User-Generated Content</p>
                <p className="mb--10">
                  When you create or upload content through the PodcastPilot
                  services, including podcasts featuring AI-generated dialogues
                  or any content that utilizes the text-to-speech (TTS)
                  functionality of our app, you retain ownership of any
                  intellectual property rights that you hold in that content.
                  However, by creating, uploading, or sharing content on or
                  through our services, you grant PlotPilot Ltd. a global,
                  non-exclusive, royalty-free, sublicensable, and transferable
                  license to use, reproduce, modify, perform, display,
                  distribute, and otherwise disclose to third parties the
                  content in connection with operating and providing the
                  services and content to you and to other users.
                </p>
                <p className="mb--10">Representation and Warranties</p>
                <p className="mb--10">You represent and warrant that:</p>
                <p className="mb--10">
                  • You have the lawful rights to create, upload, and share the
                  content and to grant the aforementioned license.
                </p>
                <p className="mb--10">
                  • The content, and its use by PlotPilot Ltd. as permitted by
                  these Terms, does not and will not infringe, violate, or
                  misappropriate any third party’s rights, including but not
                  limited to privacy rights, publicity rights, copyrights, or
                  other intellectual property rights.
                </p>
                <p className="mb--10">Content Featuring Public Figures</p>
                <p className="mb--10">
                  Given the app's capability to generate content that may mimic
                  the voices of public figures, you understand and agree that
                  you are solely responsible for ensuring that such use complies
                  with all applicable laws and does not infringe on the rights
                  of any third party, including the right of publicity or
                  privacy. PlotPilot Ltd. does not endorse any user-generated
                  content nor assume any liability for any content that may
                  infringe upon the rights of others. You agree to indemnify and
                  hold harmless PlotPilot Ltd. against any and all claims,
                  damages, obligations, losses, liabilities, costs, or debt, and
                  expenses arising from your violation of any third party's
                  rights.
                </p>
                <p className="mb--10">Use of Content</p>
                <p className="mb--10">
                  PlotPilot Ltd. reserves the right (but is not obligated) to
                  review, monitor, edit, or remove content generated by users at
                  our sole discretion, at any time, without prior notice, and
                  for any reason. Additionally, content generated by users may
                  be used by PlotPilot Ltd. for promotional purposes,
                  improvement of services, and development of new features,
                  adhering to the license granted above.
                </p>
                <p className="mb--10">Responsibility of Content</p>
                <p className="mb--10">
                  You acknowledge that all content created, uploaded, posted,
                  sent, received, or stored through the use of our service by
                  users is the sole responsibility of the person who originated
                  such content. You agree that PlotPilot Ltd. carries no
                  responsibility for any user-generated content, including but
                  not limited to any errors or omissions, or damages of any kind
                  incurred as a result of the use of any content posted,
                  emailed, transmitted, or otherwise made available through the
                  PodcastPilot services.
                </p>
                <p className="mb--10">5. Subscription Fees</p>
                <p className="mb--10">Subscription Options</p>
                <p className="mb--10">
                  PodcastPilot offers two subscription options to access its
                  premium features:
                </p>
                <p className="mb--10">• Weekly Subscription</p>
                <p className="mb--10">• Monthly Subscription</p>
                <p className="mb--10">
                  All prices are inclusive of VAT or any other applicable taxes
                  and may be subject to change
                </p>
                <p className="mb--10">Payment Terms</p>
                <p className="mb--10">
                  Upon selecting a subscription plan, you agree to pay the
                  subscription fees at the current rate, and any applicable
                  taxes, charged to your chosen payment method. Subscription
                  fees will be charged in advance, on a recurring and periodic
                  basis (the "Billing Cycle"). Billing cycles are set on a
                  weekly or monthly basis, depending on the type of subscription
                  plan you select when purchasing a subscription.
                </p>
                <p className="mb--10">
                  Your subscription will automatically renew at the end of each
                  Billing Cycle unless you cancel auto-renewal through your
                  online account management page or by contacting PodcastPilot
                  customer support. At the time of automatic renewal, the
                  subscription fee will be charged to the payment method
                  registered in your account.
                </p>
                <p className="mb--10">Price Changes</p>
                <p className="mb--10">
                  PlotPilot Ltd. reserves the right to adjust the pricing for
                  its subscriptions or any components thereof in any manner and
                  at any time as we may determine in our sole and absolute
                  discretion. Any subscription fee changes will take effect
                  following email notice to you, at least 30 days before the
                  payment of your next subscription fee is due. Your continued
                  use of the service after the subscription fee change comes
                  into effect constitutes your agreement to pay the modified
                  subscription fee amount.
                </p>
                <p className="mb--10">Cancellation Policy</p>
                <p className="mb--10">
                  You may cancel your subscription at any time via your account
                  settings or by contacting our customer support team. If you
                  cancel your subscription, you may continue to use the
                  subscription service until the end of your current Billing
                  Cycle. Cancelled subscriptions will not be refunded for the
                  remainder of the active subscription period.
                </p>
                <p className="mb--10">Free Trials</p>
                <p className="mb--10">
                  From time to time, PlotPilot Ltd. may offer free trials for
                  our subscription plans for a specified period without payment.
                  Eligibility for free trials is determined by PlotPilot Ltd. at
                  its sole discretion and may be limited to prevent free trial
                  abuse. PlotPilot Ltd. reserves the right to withdraw or modify
                  a free trial offer at any time without prior notice, at our
                  sole discretion, and without liability.
                </p>
                <p className="mb--10">
                  Please note that all free trials are subject to the terms and
                  conditions of the Google Play Store or the Apple App Store,
                  depending on your device’s operating system. Both Google and
                  Apple are solely responsible for handling subscription
                  cancellations. If a free trial is not canceled before it ends,
                  Google Play Store or Apple App Store (as applicable) will
                  automatically convert the free trial into a paid subscription.
                  To avoid being charged, the subscription must be canceled at
                  least 24 hours before the free trial ends, in accordance with
                  the terms provided by the Google Play Store or Apple App
                  Store. It is the user's responsibility to manage their
                  subscription, which can be done through their Google Play or
                  Apple App Store account settings.
                </p>
                <p className="mb--10">Termination</p>
                <p className="mb--10">
                  You may terminate these Terms at any time by closing your
                  account and discontinuing use of the PodcastPilot service.
                  PlotPilot Ltd. may terminate or suspend your access to the
                  PodcastPilot service at any time, without prior notice or
                  liability, for any reason, including but not limited to a
                  breach of these Terms. Upon termination, your right to use the
                  PodcastPilot service will immediately cease. All provisions of
                  these Terms which by their nature should survive termination
                  shall survive termination, including, without limitation,
                  ownership provisions, warranty disclaimers, indemnity, and
                  limitations of liability.
                </p>
                <p className="mb--10">6. Data Collection and Use</p>
                <p className="mb--10">Information We Collect</p>
                <p className="mb--10">
                  At PodcastPilot, we are committed to creating a personalized
                  and efficient experience for our users. To achieve this, we
                  collect the following types of information:
                </p>
                <p className="mb--10">
                  • Personal Identification Information: Includes age, date of
                  birth, and gender, which helps us ensure our content is
                  appropriately targeted and compliant with age-related
                  restrictions.
                </p>
                <p className="mb--10">
                  • User-Generated Content: Includes any content—such as posts,
                  comments, images, videos, and other materials—that you create
                  and share using our platform. Collecting this data helps us
                  foster a vibrant community, ensure compliance with our content
                  guidelines, and provide personalized experiences. It also
                  enables us to monitor for and protect against abusive or
                  copyright-infringing content.
                </p>
                <p className="mb--10">
                  • Usage Data: Encompasses data related to your interactions
                  with the app, such as features used, preferences set, and
                  engagement metrics. This information allows us to understand
                  how our app is being used and how we can improve it.
                </p>
                <p className="mb--10">
                  • Device and Technical Information: We may collect information
                  about the device and internet connection you use to access our
                  services, including the device's unique identifiers, operating
                  system, browser type, and IP address. This information aids in
                  optimizing our app’s performance across different devices and
                  platforms.
                </p>
                <p className="mb--10">
                  The information we collect is used in several ways, including
                  to:
                </p>
                <p className="mb--10">
                  • Personalize your experience within the app, ensuring content
                  and features are relevant and tailored to your preferences.
                </p>
                <p className="mb--10">
                  • Enhance the app’s functionality, address technical issues,
                  and develop new features based on user interactions and
                  feedback.
                </p>
                <p className="mb--10">
                  • Communicate with you about your account, system updates, and
                  promotional offers, subject to your communication preferences.
                </p>
                <p className="mb--10">Sharing Your Information</p>
                <p className="mb--10">
                  We value your privacy and only share your information with
                  third parties under the following circumstances:
                </p>
                <p className="mb--10">
                  • Service Providers: We engage with third-party service
                  providers to perform services on our behalf, such as hosting,
                  data analysis, marketing, and customer service. These
                  providers have access to your personal information only to
                  perform these tasks and are obligated not to disclose or use
                  it for any other purpose.
                </p>
                <p className="mb--10">
                  • Legal Requirements: We may disclose your information if
                  required by law or in response to valid requests by public
                  authorities (e.g., a court or a government agency).
                </p>
                <p className="mb--10">
                  • Business Transfers: In the event of a merger, acquisition,
                  or asset sale, your personal information may be transferred.
                  We will provide notice before your personal information is
                  transferred and becomes subject to a different privacy policy.
                </p>
                <p className="mb--10">Your Data Protection Rights</p>
                <p className="mb--10">
                  Under certain circumstances, you have rights under data
                  protection laws in relation to your personal information,
                  including the right to access, correct, or delete your
                  personal data provided to us. For more information about these
                  rights, or to exercise them, please contact us directly.
                </p>
                <p className="mb--10">Consent</p>
                <p className="mb--10">
                  By using PodcastPilot, you consent to the collection and use
                  of your information as outlined in this section and our
                  Privacy Policy. We are committed to protecting your personal
                  data and ensuring its confidentiality and security.
                </p>
                <p className="mb--10">Compliance with International Laws</p>
                <p className="mb--10">
                  PodcastPilot is committed to complying with international laws
                  and regulations, including but not limited to data protection
                  laws such as the General Data Protection Regulation (GDPR) for
                  our users in the European Union. We take your privacy
                  seriously and will take all necessary steps to ensure your
                  personal information is treated securely and in accordance
                  with this privacy policy.
                </p>
                <p className="mb--10">7. Third-Party Services</p>
                <p className="mb--10">Overview</p>
                <p className="mb--10">
                  PodcastPilot integrates with various third-party services and
                  APIs to provide enhanced features, including AI-driven content
                  generation and Text-to-Speech (TTS) functionalities. These
                  integrations are essential for delivering the innovative
                  experiences our users expect from PodcastPilot.
                </p>
                <p className="mb--10">Relationship with Third-Party Services</p>
                <p className="mb--10">
                  While we carefully select our third-party service providers
                  for their reliability and service quality, PlotPilot Ltd. does
                  not control and is not liable for the actions or policies of
                  these third parties. We do not claim ownership over the
                  content generated through third-party services and cannot
                  guarantee its accuracy, integrity, or quality.
                </p>
                <p className="mb--10">User Responsibilities</p>
                <p className="mb--10">
                  • Compliance with Third-Party Terms: Before utilizing features
                  powered by third-party services, you are responsible for
                  reviewing and agreeing to each third-party's terms and
                  conditions. Use of these features constitutes acceptance of
                  the respective third-party terms.
                </p>
                <p className="mb--10">
                  • Data Sharing and Privacy: Be aware that using third-party
                  services within PodcastPilot may result in the transfer of
                  your data outside of our app to these third-party services. We
                  encourage you to review the privacy policies of any
                  third-party services you use to understand how your
                  information may be collected, used, and shared.
                </p>
                <p className="mb--10">
                  • Liability: PlotPilot Ltd. assumes no responsibility for the
                  content, policies, or practices of any third-party service.
                  You acknowledge and agree that PlotPilot Ltd. shall not be
                  responsible or liable, directly or indirectly, for any damage
                  or loss caused or alleged to be caused by or in connection
                  with the use of or reliance on any such third-party services.
                </p>
                <p className="mb--10">Changes and Updates</p>
                <p className="mb--10">
                  PlotPilot Ltd. reserves the right to modify, replace, or
                  discontinue the integration of any third-party service at any
                  time without prior notice. We aim to continuously evaluate and
                  optimize the third-party services integrated with our app to
                  ensure the best user experience.
                </p>
                <p className="mb--10">Support and Queries</p>
                <p className="mb--10">
                  Should you have any questions or concerns regarding the use of
                  third-party services within PodcastPilot, please contact our
                  support team. We are committed to assisting you and ensuring a
                  seamless experience with our app.
                </p>
                <p className="mb--10">8. Prohibited Uses</p>
                <p className="mb--10">
                  In using PodcastPilot, you are expected to act responsibly and
                  respect the rights of others. The following activities are
                  strictly prohibited and may result in the termination of your
                  access to the app, at our sole discretion, and may also
                  subject you to legal action:
                </p>
                <p className="mb--10">Illegal Activities</p>
                <p className="mb--10">
                  • Unlawful Use: You may not use PodcastPilot for any purpose
                  that is illegal or prohibited by law in your jurisdiction.
                </p>
                <p className="mb--10">
                  • Fraudulent Behavior: Engaging in fraud, misrepresentation,
                  or phishing is strictly forbidden.
                </p>
                <p className="mb--10">Abusive Behavior</p>
                <p className="mb--10">
                  • Harassment and Abuse: Harassing, abusing, stalking,
                  threatening, or otherwise violating the legal rights (such as
                  privacy and publicity rights) of others is prohibited
                </p>
                <p className="mb--10">
                  • Harmful Content: Distributing or promoting content that is
                  obscene, defamatory, libelous, violent, discriminatory, or
                  otherwise objectionable is not allowed.
                </p>
                <p className="mb--10">
                  • Hacking and Unauthorized Access: Attempting to gain
                  unauthorized access to PodcastPilot, other accounts, computer
                  systems, or networks connected to the PodcastPilot Service,
                  through hacking, password mining, or any other means, is
                  prohibited.
                </p>
                <p className="mb--10">
                  • Interference with Service: You must not interfere with or
                  disrupt the services or servers or networks connected to the
                  services, or disobey any requirements, procedures, policies,
                  or regulations of networks connected to the PodcastPilot.
                </p>
                <p className="mb--10">Intellectual Property Infringement</p>
                <p className="mb--10">
                  • Copyright Violation: You may not upload, download, post,
                  email, or otherwise transmit any material that infringes on
                  the copyright, patent, trademark, trade secret, or other
                  intellectual property or proprietary rights of any party.
                </p>
                <p className="mb--10">
                  • Misuse of Third-Party Rights: Using the app to impersonate
                  any person or entity, including using the voices of public
                  figures without authorization, or falsely state or otherwise
                  misrepresent your affiliation with a person or entity, is
                  forbidden.
                </p>
                <p className="mb--10">Exploitation and Commercial Use</p>
                <p className="mb--10">
                  • Unauthorized Commercial Use: You may not use PodcastPilot
                  for any unauthorized commercial purposes, including but not
                  limited to, advertising or offering to sell or buy any goods
                  or services, conducting contests, or as part of a pyramid
                  scheme.
                </p>
                <p className="mb--10">Compliance with Laws</p>
                <p className="mb--10">
                  • Adherence to Laws: You must comply with all local, state,
                  national, and international laws and regulations applicable to
                  your use of PodcastPilot, including laws related to data
                  privacy, international communications, and the transmission of
                  technical or personal data.
                </p>
                <p className="mb--10">Reporting Violations</p>
                <p className="mb--10">
                  • We encourage the community to report any misuse of the
                  service or violation of these Terms. PlotPilot Ltd. reserves
                  the right, but is not obligated, to investigate and take
                  appropriate legal action against anyone who violates these
                  provisions, including removing the offending communication
                  from the service and terminating the violator's access to the
                  app.
                </p>
                <p className="mb--10">9. Amendment of Terms</p>
                <p className="mb--10">Right to Amend</p>
                <p className="mb--10">
                  PlotPilot Ltd. retains the right, at its sole discretion, to
                  modify, alter, or otherwise update these Terms and Conditions
                  at any time. Such amendments may reflect changes to our
                  service policies, enhancements of user protections, or
                  adjustments in compliance with legal and regulatory standards.
                </p>
                <p className="mb--10">Notification of Changes</p>
                <p className="mb--10">
                  • Notification Method: Whenever significant changes are made
                  to these Terms and Conditions, we will provide you with clear
                  and prominent notice of the amendments. This notice will be
                  delivered through one or more of the following channels:
                  direct notification within the PodcastPilot app, an
                  announcement on our website, or via email to the address
                  associated with your account.
                </p>
                <p className="mb--10">
                  • Review Period: We aim to ensure that you have sufficient
                  time to review any changes to the Terms and Conditions. Except
                  in urgent cases (such as those required for legal compliance,
                  to address security risks, or to prevent harm), changes will
                  become effective no sooner than 14 days after they are posted.
                  However, changes addressing new functions for the PodcastPilot
                  service or changes made for legal reasons will be effective
                  immediately.
                </p>
                <p className="mb--10">Acceptance of Changes</p>
                <p className="mb--10">
                  • Implicit Acceptance: By continuing to access or use the
                  PodcastPilot app after any revisions become effective, you
                  agree to be bound by the revised terms. If you do not agree to
                  the new terms, you are no longer authorized to use the
                  service.
                </p>
                <p className="mb--10">
                  • Active Confirmation: Depending on the nature and
                  significance of the changes, we may also seek your active
                  confirmation of the revised terms through an in-app prompt or
                  a click-through confirmation at login.
                </p>
                <p className="mb--10">Historical Versions</p>
                <p className="mb--10">
                  • Access to Previous Terms: We understand the importance of
                  transparency in how our terms evolve. Upon request, we will
                  provide you with the previous versions of our Terms and
                  Conditions for your review.
                </p>
                <p className="mb--10">10. Governing Law</p>
                <p className="mb--10">
                  These Terms shall be governed and construed in accordance with
                  the laws of The United Kingdom without regard to its conflict
                  of law provisions.
                </p>
                <p className="mb--10">11. Contact Us</p>
                <p className="mb--10">Feedback and Reporting</p>
                <p className="mb--10">
                  We welcome and encourage feedback from our users. If you have
                  any suggestions for improving PodcastPilot, or if you need to
                  report a violation of our Terms, abuse, or any other concerns,
                  please contact us at accounts@plotpilot.ai. We are committed
                  to working with our users to ensure a respectful and secure
                  environment.
                </p>
                <p className="mb--10">
                  If you have any questions about these Terms, please contact us
                  at accounts@plotpilot.ai.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default Company;
