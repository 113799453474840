import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    image: "/images/service/cover-step-1.png",
    title: "Create Your Dream Podcast Lineup",
    description:
      "Use the PodcastPilot assistant to effortlessly create your ideal podcast guests and hosts. Let your imagination soar as you design the perfect personalities to bring your podcast vision to life.",
  },
  {
    image: "/images/service/cover-step-2.png",
    title: "Choose Your Role and Dive In",
    description:
      "Decide whether you want to take the reins as the host, guiding the podcast's direction, or step into the guest's shoes and be interviewed by your AI co-host. With PodcastPilot, you have the flexibility to explore both roles and create dynamic, engaging content.",
  },
  {
    image: "/images/service/cover-step-3.png",
    title: "Share Your Podcast with the World",
    description:
      "Once you've recorded your podcast, effortlessly export it to share with friends, family, or your growing audience. PodcastPilot makes it simple to distribute your content, allowing you to focus on creating while we handle the rest.",
  },
];
const ServiceThree = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="inner">
                <div className="image">
                  <img src={`${val.image}`} alt="card Images" />
                </div>
                <div className="content">
                  <h4
                    className="title"
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceThree;
