import React from "react";
import SEO from "../common/SEO";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import { Link } from "react-router-dom";
import HeaderOne from "../common/header/HeaderOne";
import TabThree from "../elements/tab/TabThree";
import AboutTwo from "../elements/about/AboutTwo";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import BrandOne from "../elements/brand/BrandOne";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import { Helmet } from "react-helmet";

const BannerData = [
  {
    image: "/images/bg/bg-image-20.jpg",
    title: "Where AI Podcasts",
    description:
      "Create your dream podcast co-host and dive into captivating<br /> conversations like never before!",
  },
];

const WebAgency = () => {
  return (
    <>
      <SEO title="PodcastPilot" />
      <Helmet>
        <meta
          name="google-site-verification"
          content="-VuF2N5aQS_ndLNi2LWWaeMRen8bS11u0FF_-XrtFPQ"
        />
      </Helmet>
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-8 height-650">
          {BannerData.map((data, index) => (
            <div key={index} className="single-slide">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="inner text-center">
                      <h1 className="title">
                        {" "}
                        {data.title} <br />
                        <span className="theme-gradient">take flight</span>
                      </h1>
                      <p
                        className="description"
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      ></p>
                      <div className="button-group mt--30">
                        <a
                          className="btn-default btn-large round"
                          target="_blank"
                          href="/download"
                        >
                          Try PodcastPilot
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* End Slider Area  */}

        {/* Start Counterup Area  */}
        <div className="our-counterup-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="radius w-100"
                    src="./images/bg/bg-image-21.png"
                    alt="Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Counterup Area  */}

        <Separator />

        {/* Start Service Area  */}
        <div className="rwt-elements-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--10">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Co-Host Your Podcasts with Community Built Characters"
                  title="Community Built Characters"
                />
              </div>
            </div>
            <TestimonialOne
              column="col-lg-4 col-md-6 col-12"
              teamStyle="card-style-default testimonial-style-one style-two border-gradient"
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Service Area  */}
        <div className="rwt-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Unlock Endless Possibilities with PodcastPilot"
                  title="Engage in captivating conversations with AI personalities on any topic imaginable."
                  description="Here are some of the ways users have engaged and dynamic conversations with AI personalities."
                />
              </div>
            </div>
            <TabThree />
          </div>
        </div>
        {/* End Service Area  */}

        <AboutTwo />

        {/* Start Elements Area  */}
        <div className="rwt-testimonial-area rn-section-gap">
          <div className="container">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Feedback"
                  title="Our User Feedback."
                  description="Join Podcasters from around the world who are already using PodcastPilot to create engaging and dynamic conversations with AI personalities."
                />
              </div>
            </div>
            <TestimonialThree teamStyle="" />
          </div>
        </div>
        {/* End Elements Area  */}

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default WebAgency;
