import React from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterTwo from "../common/footer/FooterTwo";

import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

import Separator from "../elements/separator/Separator";
const Marketing = () => {
  return (
    <>
      <SEO title="Try PodcastPilot" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-start">
                  <h1 className="title theme-gradient display-two">
                    Dive into a world <br /> of{" "}
                    <Typed
                      strings={[
                        "Discussions",
                        "Laughter",
                        "Learning",
                        "Fun",
                        "Adventure",
                        "Discovery",
                      ]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">
                    Jump into discussions and talk with anyone about anything.
                  </p>
                  <div className="button-group">
                    <a
                      href="https://apps.apple.com/us/app/plotpilot-ai-powered-stories/id6479552964"
                      className="btn-default btn-medium btn-border round btn-icon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download on the App Store{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 order-1 order-lg-2">
                <div className="thumbnail">
                  <img
                    src="./images/about/download-app-store.png"
                    alt="Banner Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        <FooterTwo />
      </main>
    </>
  );
};

export default Marketing;
