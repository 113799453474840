import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";

const dataList = [
  {
    image: "/images/service/learning-1-podcast.jpeg",
    title: "Dr. Sophia Nova",
    description:
      "Explore the frontiers of astrophysics with Dr. Sophia Nova, a brilliant scientist at the forefront of space exploration.",
  },
  {
    image: "/images/service/learning-2-podcast.jpeg",
    title: "Professor Liam Hawthorne",
    description:
      "Dive into philosophy and ethics with Professor Liam Hawthorne, a renowned thinker tackling life's big questions.",
  },
];

const dataList2 = [
  {
    image: "/images/service/albert-podcast.jpeg",
    title: "Albert Einstein",
    description:
      "Unravel the mysteries of the universe with Albert Einstein as your AI conversation companion.",
  },
  {
    image: "/images/service/oppenheimer-podcast.jpeg",
    title: "Oppenheimer",
    description:
      "Delve into the complexities of science and morality with J. Robert Oppenheimer.",
  },
];

const dataList3 = [
  {
    image: "/images/service/fictional-1-podcast.jpeg",
    title: "Sherlock Holmes",
    description:
      "Solve perplexing mysteries alongside the legendary detective Sherlock Holmes, as he shares his deductive reasoning skills.",
  },
  {
    image: "/images/service/fictional-2-podcast.jpeg",
    title: "Lenny Chuckles",
    description:
      "Get ready to laugh out loud as you join comedian Lenny Chuckles for hilarious stories and witty observations about his life's misadventures.",
  },
];

const TabThree = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-12">
                <div className="rn-default-tab style-three">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Historical Figures</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Learning</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Fictional</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-4">
                              <div className="section-title">
                                <h4 className="title">
                                  Conversations re-imagined
                                </h4>
                                <p>
                                  Go back in time and chat with some of the most
                                  influential people in history.
                                </p>
                                <p>*Community Created Characters*</p>
                              </div>
                            </div>
                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                {dataList2.map((val, i) => (
                                  <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    key={i}
                                  >
                                    <div className="card-box card-style-1 text-start">
                                      <div className="inner">
                                        <div className="image">
                                          <img
                                            src={`${val.image}`}
                                            alt="card Images"
                                          />
                                        </div>
                                        <div className="content">
                                          <h4
                                            className="title mb--20"
                                            dangerouslySetInnerHTML={{
                                              __html: val.title,
                                            }}
                                          ></h4>
                                          <p
                                            className="description b1 color-gray mb--0"
                                            dangerouslySetInnerHTML={{
                                              __html: val.description,
                                            }}
                                          ></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-4">
                              <div className="section-title">
                                <h4 className="title">Expand Your Knowledge</h4>
                                <p>
                                  Engage in personalized, in-depth discussions
                                  with AI-powered subject matter experts and
                                  elevate your learning experience
                                </p>
                                <p>*Community Created Characters*</p>
                              </div>
                            </div>
                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                {dataList.map((val, i) => (
                                  <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    key={i}
                                  >
                                    <div className="card-box card-style-1 text-start">
                                      <div className="inner">
                                        <div className="image">
                                          <img
                                            src={`${val.image}`}
                                            alt="card Images"
                                          />
                                        </div>
                                        <div className="content">
                                          <h4
                                            className="title mb--20"
                                            to="#service"
                                            dangerouslySetInnerHTML={{
                                              __html: val.title,
                                            }}
                                          ></h4>
                                          <p
                                            className="description b1 color-gray mb--0"
                                            dangerouslySetInnerHTML={{
                                              __html: val.description,
                                            }}
                                          ></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-4">
                              <div className="section-title">
                                <h4 className="title">Made from scratch!</h4>
                                <p>
                                  Create entirely new personalities from
                                  scratch, each with their own quirks,
                                  backstories, and areas of expertise.
                                </p>
                                <p>*Community Created Characters*</p>
                              </div>
                            </div>
                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                {dataList3.map((val, i) => (
                                  <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    key={i}
                                  >
                                    <div className="card-box card-style-1 text-start">
                                      <div className="inner">
                                        <div className="image">
                                          <img
                                            src={`${val.image}`}
                                            alt="card Images"
                                          />
                                        </div>
                                        <div className="content">
                                          <h4
                                            className="title mb--20"
                                            dangerouslySetInnerHTML={{
                                              __html: val.title,
                                            }}
                                          ></h4>
                                          <p
                                            className="description b1 color-gray mb--0"
                                            dangerouslySetInnerHTML={{
                                              __html: val.description,
                                            }}
                                          ></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabThree;
