import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li>
        <Link to="/start-ai-podcasts">Start My AI Podcasts</Link>
      </li>

      <li className="with-megamenu">
        <Link to="#">Next-Gen Podcasts</Link>
        <div className="rn-megamenu">
          <div className="wrapper">
            <div className="row row--0">
              <div className="col-lg-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link to="/assign-voices">Assign Voices</Link>
                  </li>
                  <li>
                    <Link to="/co-stars">Co-stars</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link to="/community-personalities">
                      Community Personalities
                    </Link>
                  </li>
                  <li>
                    <Link to="/switch-roles">Switch Roles</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link to="/referral-program">Referral Program</Link>
                  </li>
                  <li>
                    <Link to="/creator-program">Creator Program</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <li>
                      <Link to="/terms">Terms and Conditions</Link>
                    </li>
                  </li>
                  <li>
                    <li>
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li className="has-droupdown">
        <Link to="#">Join us</Link>
        <ul className="submenu">
          <li>
            <a
              href="https://www.tiktok.com/@podcastpilot"
              target="_blank"
              rel="noopener noreferrer"
            >
              TikTok
            </a>
          </li>
          <li>
            <a
              href="https://www.twitter.com/@podcastpilotHQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </li>
          <li>
            <a
              href="https://discord.gg/JrXCsZzswD"
              target="_blank"
              rel="noopener noreferrer"
            >
              Discord
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/podcastpilotHQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </li>
        </ul>
      </li>

      <li className="has-droupdown">
        <Link to="/support">Support</Link>
      </li>
    </ul>
  );
};
export default Nav;
