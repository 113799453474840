import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const AccordionOne = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey="0"
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            What is PodcastPilot's AI Assistant?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            PodcastPilot's AI assistant uses advanced language processing and
            intelligent conversation generation to help you design unique,
            engaging, and lifelike podcast personalities. Simply input your
            preferences and ideas, and the assistant will create custom guests
            and hosts tailored to your vision, complete with their own
            expertise, conversational styles, and unique traits{" "}
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            Can I choose my role in the podcast conversation?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            Yes! With PodcastPilot, you have the flexibility to choose your role
            in the podcast. You can take the lead as the host, guiding the
            discussion and asking questions that matter to you and your
            audience. Alternatively, you can be interviewed by your AI co-host,
            allowing you to share your insights and experiences from a guest's
            perspective. PodcastPilot empowers you to explore both roles and
            create diverse, engaging content.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="2">
            How can I share my podcast with others?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            PodcastPilot makes it easy to share your podcast with the world.
            Once you've recorded your episode, simply use the app's built-in
            export feature to save your podcast in a format compatible with
            popular podcast platforms and social media. From there, you can
            distribute your content to your existing audience or share it with
            friends, family, and new listeners.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="3">
            How do I create my own unique podcast co-host or guest using
            PodcastPilot?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            Creating your own unique podcast co-host or guest is easy with
            PodcastPilot's intuitive 'Create Character' feature. Simply navigate
            to the 'Create Character' page within the app, where you can fully
            customize your AI personality from scratch. Start by tailoring the
            character's appearance, choosing from a wide range of options to
            design a look that perfectly fits your vision. Next, define your
            character's speaking style, tone of voice, and personality traits,
            making them as distinct and engaging as you'd like. Specify their
            areas of expertise to ensure they can provide informed and
            captivating insights on the topics you want to discuss. Finally,
            bring your character to life by assigning them a voice – either
            record your own or choose from PodcastPilot's extensive library of
            community-crafted voices. With these tools at your fingertips, you
            have the power to create truly unique and memorable podcast
            personalities that will keep your listeners coming back for more.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default AccordionOne;
