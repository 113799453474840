import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const AboutTwo = () => {
  return (
    <div className="rwt-about-area about-style-2 rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-7 mt_md--30 mt_sm--30">
            <div className="content">
              <div className="section-title">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h4 className="subtitle">
                    <span className="theme-gradient">
                      Personalize Your Podcasts
                    </span>
                  </h4>
                  <h2 className="title mt--10">
                    Create Your Own Unique Co-Stars
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p>
                    PodcastPilot empowers you to create your own unique co-stars
                    for your podcasts. Customize their appearance, personality,
                    backstory, and conversational style to fit your podcast's
                    tone and topic. Craft the perfect sidekick, expert, or
                    contrarian to elevate your podcasting game.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                src="./images/about/co-stars-1.png"
                alt="About Images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
