import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const AboutTwo = () => {
  return (
    <div className="rwt-about-area about-style-2 rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-7 mt_md--30 mt_sm--30">
            <div className="content">
              <div className="section-title">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h4 className="subtitle">
                    <span className="theme-gradient">Invite & Earn</span>
                  </h4>
                  <h2 className="title mt--10">
                    Unlock Exclusive Rewards with PodcastPilot's Referral
                    Program
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p>
                    Share the excitement of PodcastPilot with your friends and
                    unlock exclusive rewards! Our referral program offers early
                    access to new features, creation credits, and more. The more
                    friends you invite, the more rewards you earn. Start sharing
                    today and take your podcasting experience to the next level.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                src="./images/about/referral-program.png"
                alt="About Images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
