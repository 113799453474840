import React from "react";
import ReactDOM from "react-dom"; // Change this line
import App from "./App";

// Remove the following line
// const root = ReactDOM.createRoot(document.getElementById("root"));

ReactDOM.render(
  // Change this line
  <>
    <App />
  </>,
  document.getElementById("root") // Add this line
);
