import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const testimonialData = [
  {
    image: "testimonial-01",
    name: "Amelia Lockhart",
    designation: "53.k Chats",
    description:
      "“A witty and sophisticated Victorian-era detective, ready to engage in intellectually stimulating conversations and share tales of her most perplexing cases.„",
  },
  {
    image: "testimonial-02",
    name: "Dr. Evelyn Sage",
    designation: "95k Chats",
    description:
      "“A brilliant and eccentric scientist who has unlocked the secrets of time travel, eager to share her mind-bending theories and experiences.„",
  },
  {
    image: "testimonial-03",
    name: "Jasper Quasar",
    designation: "19k Chats",
    description:
      "“A UFO enthusiast who claims to have had multiple alien encounters, ready to share his extraordinary experiences and theories on extraterrestrial life.„",
  },
];

const TestimonialOne = ({ column, teamStyle }) => {
  return (
    <div className="row row--15">
      {testimonialData.map((data, index) => (
        <div className={`${column}`} key={index}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`rn-box-card ${teamStyle}`}>
              <div className="inner">
                <figure className="thumbnail">
                  <img
                    src={`./images/testimonial/${data.image}.jpeg`}
                    alt="Corporate React Template"
                  />
                </figure>
                <figcaption className="content">
                  <p className="description">{data.description}</p>
                  <h2 className="title">{data.name}</h2>
                  <h6 className="subtitle theme-gradient">
                    {data.designation}
                  </h6>
                </figcaption>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};

export default TestimonialOne;
