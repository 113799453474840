import React from "react";
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
  {
    description:
      "I love how easy it is to create captivating podcasts with PodcastPilot. The AI characters are so diverse and bring unique perspectives to every discussion. It's like having a lively group of friends to chat with anytime, anywhere!",
    name: "Lauren_g",
    subtitle: "4.5/5",
    image: "testimonial-dark-01",
  },
  {
    description:
      "I never knew talking to AI characters could be so fun and engaging until I tried PodcastPilot. The conversations flow naturally, and the personalities have a great sense of humor. It's like having a witty and intelligent companion to banter with whenever I'm in the mood for a stimulating discussion.",
    name: "Gabriel",
    subtitle: "4.6/5",
    image: "testimonial-dark-02",
  },
  {
    description:
      "I'm blown away by the depth and quality of the conversations I can have with the AI personalities on PodcastPilot. They challenge my ideas, offer fresh perspectives, and make me think in ways I never have before.",
    name: "Jenny",
    subtitle: "4.8/5",
    image: "testimonial-dark-03",
  },
  {
    description:
      "The AI characters are like having a panel of experts at my fingertips, ready to dive into any topic I throw at them. The conversations are so natural and dynamic, it's hard to believe I'm talking to virtual personalities.",
    name: "Lucy K",
    subtitle: "4.6/5",
    image: "testimonial-dark-04",
  },
];
const TestimonialThree = ({ teamStyle }) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <Slider
          className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60"
          {...slickDot}
        >
          {TestimonialData.map((data, index) => (
            <div key={index} className={`testimonial-style-two ${teamStyle}`}>
              <div className="row align-items-center row--20">
                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                  <div className="content mt_sm--40">
                    <span className="form">{data.form}</span>
                    <p className="description">{data.description}</p>
                    <div className="client-info">
                      <h4 className="title">{data.name}</h4>
                      <h6 className="subtitle">{data.subtitle}</h6>
                    </div>
                  </div>
                </div>
                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={`./images/testimonial/${data.image}.jpeg`}
                      alt="Corporate React Template"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default TestimonialThree;
