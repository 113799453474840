import React from "react";
import { FiMail } from "react-icons/fi";

const ContactOne = () => {
  return (
    <>
      <div className="row row--15 justify-content-center">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address text-center">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">PodcastPilot Support</h4>
                    <p>
                      <a href="mailto:accounts@plotpilot.ai">
                        accounts@plotpilot.ai
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactOne;
