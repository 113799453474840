import React from "react";
import SEO from "../../common/SEO";
import HeaderOne from "../../common/header/HeaderOne";
import FooterTwo from "../../common/footer/FooterTwo";
import Copyright from "../../common/footer/Copyright";
import ScrollTop from "../../common/footer/ScrollTop";
import Separator from "../../elements/separator/Separator";
import AboutSwitchRoles from "../../elements/about/AboutSwitchRoles";

const BannerData = [
  {
    image: "/images/bg/bg-image-20.jpg",
    title: "Switch up your podcast",
    description:
      "Become the Host or Guest of your podcast and experience the conversation from a new perspective!",
  },
];

const CreativePortfolio = () => {
  return (
    <>
      <SEO title="Switch Roles" />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-8 height-650">
          {BannerData.map((data, index) => (
            <div key={index} className="single-slide">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="inner text-center">
                      <h1 className="title">
                        {" "}
                        {data.title} <br />
                        <span className="theme-gradient">Role</span>
                      </h1>
                      <p
                        className="description"
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      ></p>
                      <div className="button-group mt--30">
                        <a
                          className="btn-default btn-large round"
                          target="_blank"
                          href="/download"
                        >
                          Try PodcastPilot
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* End Slider Area  */}

        <Separator />

        <AboutSwitchRoles />

        <FooterTwo />
        <Copyright />
        <ScrollTop />
      </main>
    </>
  );
};
export default CreativePortfolio;
