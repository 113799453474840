import React from "react";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from "../accordion/AccordionOne";

const mission = () => {
  return (
    <div className="rn-company-mission-are rn-section-gap">
      <div className="container">
        <div className="row row--30">
          <div className="col-lg-6">
            <div className="mission-title">
              <h2 className="title">
                Discover a realm of
                <br />{" "}
                <Typed
                  className="theme-gradient"
                  strings={[
                    "Insightful Interviews",
                    "Hilarious Conversations",
                    "Immersive Stories",
                  ]}
                  typeSpeed={80}
                  backSpeed={5}
                  backDelay={1000}
                  loop
                />
              </h2>
              <p>
                With PodcastPilot, you can bring your dream podcast to life by
                designing your ideal guests and hosts, each with their own
                unique personalities, expertise, and conversational styles.
                Whether you want to engage in thought-provoking discussions,
                explore niche topics, or simply entertain your audience,
                PodcastPilot provides you with the tools to create the perfect
                lineup for your show.
              </p>

              <p>
                Once you've created your podcast personalities, PodcastPilot
                allows you to choose your role in the conversation. You can take
                the lead as the host, guiding the discussion and asking the
                questions that matter most to you and your listeners.
                Alternatively, you can step into the guest's role and be
                interviewed by your AI co-host, providing a fresh perspective
                and sharing your unique insights with your audience.
              </p>

              <div className="read-more-btn mt--50">
                <Link className="btn-default btn-icon" to="/download">
                  Try PodcastPilot
                  <i className="icon">
                    <FiArrowRight />
                  </i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt_md--30 mt_sm--30">
            <AccordionOne customStyle="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default mission;
