import React from "react";
import Typed from "react-typed";
import SEO from "../common/SEO";
import { FiArrowRight } from "react-icons/fi";
import HeaderOne from "../common/header/HeaderOne";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import ServiceThree from "../elements/service/ServiceThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";

const BusinessConsulting2 = () => {
  return (
    <>
      <SEO title="Start Podcasting" />
      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span className="subtitle">Create AI Podcasts</span>
                  <h1 className="title theme-gradient display-two">
                    {" "}
                    Deep Discussions about <br />{" "}
                    <Typed
                      strings={[
                        "Anything.",
                        "Life.",
                        "Philosophy.",
                        "Science.",
                        "Creativity.",
                        "Love.",
                        "History.",
                        "Adventures.",
                        "Dreams.",
                        "Humor.",
                      ]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">Where conversations come alive.</p>
                  <div className="button-group">
                    <a
                      className="btn-default btn-medium round btn-icon"
                      target="_blank"
                      href="/download"
                    >
                      Try the app
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="How to start"
                  title="Begin your AI Podcast Journey!"
                  description="With PodcastPilot, setting up a podcast is as simple as <br /> choosing your dream guest and host, and letting your imagination run wild."
                />
              </div>
            </div>
            <ServiceThree serviceStyle="gallery-style" textAlign="text-start" />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        {/* Start Mission Area   */}
        <Mission />
        {/* Start Mission Area  */}

        <Separator />
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default BusinessConsulting2;
